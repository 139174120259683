import {
  Anchor,
  Box,
  Column,
  ColumnProps,
  FlexBox,
  LayoutGrid,
  Video,
  VideoProps,
} from '@codecademy/gamut';
import * as React from 'react';

import { PausableImage } from '../PausableImage';
import { CTA } from './CTA';
import { Description } from './Description';
import { Title } from './Title';
import { TitleWithEyebrow } from './TitleWithEyebrow';
import { CtaProps, LandingPageBaseProps } from './types';

export type ColumnSize = Extract<ColumnProps['size'], number>;
export type MediaColumnsSize = Extract<ColumnSize, 3 | 4 | 5 | 6>;

export type ImageProps = {
  src: string;
  alt: string;
};

export type MediaProps =
  | ({
      type: 'image';
      linkUrl?: string;
    } & ImageProps)
  | ({
      type: 'video';
    } & VideoProps);

export type Eyebrow = {
  accent?: boolean;
  text: string;
};

export type FeaturedImageProps = ImageProps & {
  linkUrl?: string;
  hideImageOnMobile?: boolean;
};
export const FeaturedImage: React.FC<FeaturedImageProps> = ({
  src,
  alt,
  linkUrl,
  hideImageOnMobile,
}) => {
  const Container = linkUrl ? Anchor : Box;

  return (
    <Container
      display={{
        _: hideImageOnMobile ? 'none' : 'initial',
        sm: 'initial',
      }}
      href={linkUrl}
      width={1}
      tabIndex={linkUrl ? 0 : undefined}
    >
      <PausableImage src={src} alt={alt} data-testid="feature-image" />
    </Container>
  );
};

export type PageSingleFeatureProps = LandingPageBaseProps & {
  /**
   * Object containing eyebrow text shown above title and optional accent boolean
   */
  eyebrow?: Eyebrow;
  /**
   * Whether the image should be hidden on mobile. Note that videos are always shown on mobile for accessibility
   */
  hideImageOnMobile?: boolean;
  /**
   * If the heading is the page h1 heading or a smaller heading
   */
  isPageHeading?: boolean;
  /**
   * Whether to show an image or a video, with the associated props to do so
   */
  media?: MediaProps;
  /**
   * Number of columns out of 12 that the media should take up, defaults to 6. The remaining columns will be used for the text
   */
  mediaWidth?: MediaColumnsSize;
  /**
   * Optional array of additional CTAs if more than one CTA is added to the section. These will appear after the provided singular CTA.
   */
  additionalCtas?: CtaProps[];
};

export const PageSingleFeature: React.FC<PageSingleFeatureProps> = ({
  cta,
  desc,
  eyebrow,
  hideImageOnMobile,
  isPageHeading,
  media,
  mediaWidth = 6,
  onAnchorClick,
  testId,
  title,
  additionalCtas,
}) => {
  const hasAdditionalCtas = additionalCtas && additionalCtas.length > 0;

  const primaryContent = (
    <>
      {title &&
        (eyebrow ? (
          <TitleWithEyebrow
            title={title}
            eyebrow={eyebrow}
            isPageHeading={isPageHeading}
          />
        ) : (
          <Title isPageHeading={isPageHeading}>{title}</Title>
        ))}
      {desc && <Description text={desc} onAnchorClick={onAnchorClick} />}
      {cta && (
        <FlexBox
          mt={32}
          gap={16}
          flexDirection={{
            _: 'column',
            sm: cta.buttonType === 'fill' && mediaWidth < 5 ? 'row' : 'column',
            md: 'row',
          }}
          maxWidth={{ _: '100%', sm: 'max-content' }}
        >
          <CTA
            key={cta.text}
            href={cta.href}
            onClick={cta.onClick}
            buttonType={hasAdditionalCtas ? 'fill' : cta.buttonType}
          >
            {cta.text}
          </CTA>
          {hasAdditionalCtas
            ? additionalCtas.map((cta) => {
                if (!cta) {
                  return null;
                }

                return (
                  <CTA
                    key={cta.text}
                    href={cta.href}
                    onClick={cta.onClick}
                    buttonType="fill"
                  >
                    {cta.text}
                  </CTA>
                );
              })
            : null}
        </FlexBox>
      )}
    </>
  );

  if (!media) {
    return <div data-testid={testId}>{primaryContent}</div>;
  }

  const textWidth = (12 - mediaWidth) as ColumnSize;

  return (
    <LayoutGrid data-testid={testId} rowGap={16} columnGap={{ _: 8, sm: 32 }}>
      <Column size={{ sm: textWidth }} alignContent="flex-start">
        {primaryContent}
      </Column>
      <Column
        size={{ sm: mediaWidth }}
        gridRowStart={{ _: hideImageOnMobile ? 'initial' : 1, sm: 'initial' }}
        alignContent="flex-start"
      >
        {media.type === 'image' && (
          <FeaturedImage {...media} hideImageOnMobile={hideImageOnMobile} />
        )}
        {media.type === 'video' && <Video {...media} />}
      </Column>
    </LayoutGrid>
  );
};
