import { ContentContainer } from '@codecademy/gamut';
import { Background, variant } from '@codecademy/gamut-styles';
import { css } from '@emotion/react';
import styled from '@emotion/styled';
import React from 'react';

import { LandingPageSection } from './types';

const Row = styled(Background)(
  variant({
    base: {
      '&:first-of-type': {
        pt: { _: 48, sm: 64 },
      },
      '&:last-of-type': {
        pb: { _: 48, sm: 64 },
      },
    },
    variants: {
      section: {
        py: { _: 40, sm: 48 },
      },
      sectionWithBackground: {
        py: { _: 48, sm: 64 },
      },
    },
  })
);

const ScrollTarget = styled.div(
  ({ theme }) =>
    // add 1 extra rem for some breathing room
    css`
      scroll-margin-top: calc(${theme.elements.headerHeight} + 1rem);
    `
);

export type LandingPageSectionWrapperProps = Pick<
  LandingPageSection,
  'sectionBackgroundColor' | 'componentName'
> &
  React.PropsWithChildren;

export const LandingPageSectionWrapper: React.FC<
  LandingPageSectionWrapperProps
> = ({ sectionBackgroundColor, componentName, children }) => {
  return (
    <Row
      bg={sectionBackgroundColor ?? 'background-current'}
      variant={sectionBackgroundColor ? 'sectionWithBackground' : 'section'}
    >
      <ScrollTarget id={componentName ?? undefined} />
      <ContentContainer>{children}</ContentContainer>
    </Row>
  );
};
