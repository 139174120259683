import { Text } from '@codecademy/gamut';
import React from 'react';

import { Eyebrow } from './PageSingleFeature';
import { Title } from './Title';

export interface TitleWithEyebrowProps {
  eyebrow: Eyebrow;
  title: string | undefined;
  isPageHeading: boolean | undefined;
}

export const TitleWithEyebrow: React.FC<TitleWithEyebrowProps> = ({
  title,
  eyebrow,
  isPageHeading,
}) => (
  <div>
    <Title isPageHeading={isPageHeading}>
      <Text
        fontSize={{ _: 20, sm: 22 }}
        fontFamily="accent"
        mb={16}
        display="block"
        color={eyebrow.accent ? 'blue' : undefined}
      >
        {eyebrow.text}
      </Text>
    </Title>
    <Text
      fontSize={isPageHeading ? { _: 34, sm: 44, lg: 64 } : { _: 26, lg: 34 }}
      fontWeight={700}
      lineHeight="title"
    >
      {title}
    </Text>
  </div>
);
